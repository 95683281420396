
import React from 'react';
import { jsx, Container, Box, Grid, Text, Heading, Image } from 'theme-ui';

export default function Terms() {
    return (
        <>
        <Container sx={styles.container}>
            <h2>Terms and Conditions</h2>
            <p>These terms and conditions (the "Terms and Conditions") govern the use of the Avintageo wine app (the "App") and www.avintageo.com (the "Site"). This site is owned and operated by Avintageo Inc. This app is a wine prediction app. </p>

            <p>By using the site or app, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.</p>
            <p><u>Intellectual Property</u></p>
            <p>All content published and made available on our Site and App is the property of Avintageo Inc and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site or App.</p>
            <p><u>Age Restrictions</u></p>
            <p>The minimum age to use our Site is 19 years old. By using this Site, users agree that they are over 19 years old. We do not assume any legal responsibility for false statements about age. </p>
            <p><u>Acceptable Use</u></p>
            <p>As a user of our Site or App, you agree to use our Site and App legally, not use our Site and App for illegal purposes, and not to:</p>
            <ul>
                <li>Violate the intellectual property rights of the Site owners or any third party to the Site;</li>
                <li>Hack into the account of another user of the Site or App; or</li>
                <li>Post any material that may be deemed inappropriate or offensive</li>
            </ul>
            <p>If we believe you are using our Site or App illegally or in a manner that violates these Terms and Conditions, we reserve the right to limit, suspend or terminate your access to our Site or App. We also reserve the right to take any legal steps necessary to prevent you from accessing our Site or App.</p>

            <p><u>Accounts</u></p>
            <p>When you create an account on our App or Site, you agree to the following:</p>
            <ol>
                <li>You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and</li>
                <li>All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.</li>
            </ol>
            <p>We reserve the right to suspend or terminate your account if you are using our Site or App illegally or if you violate these Terms and Conditions.</p>
            <p><u>Links to Other Websites</u></p>
            <p>Our Site may contain links to third party websites or services that we do not own or control. We are not responsible for the content, policies, or practices of any third party website or service linked to on our Site. It is your responsibility to read the terms and conditions and privacy policies of these third party websites before using these sites.</p>
            <p><u>Limitation of Liability</u></p>
            <p>Avintageo Inc and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site or App.</p>
            <p><u>Indemnity</u></p>
            <p>Except where prohibited by law, by using this Site you indemnify and hold harmless Avintageo Inc and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site and App or your violation of these Terms and Conditions.</p>
            <p><u>Applicable Law</u></p>
            <p>These Terms and Conditions are goverend by laws of the Province of Ontario.</p>
            <p><u>Severability</u></p>
            If at any time any of the provisions set for in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Condisions will still be considered valid.
            <p><u>Changes</u></p>
            <p>These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site or App and the way we expect users to behave on our Site and App. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.</p>
            <p><u>Contact Details</u></p>
            <p>Please contact us if you have any questions of concerns. Our contact details are as follows:</p>
            <p>info@avintageo.com</p>
            <p>225 The East Mall, Toronto, ON M9B 8B9</p>
            <p>You can also contact us through the feedback form available on our Site.</p>
        </Container>

        
        </>


    );
  }
  
  const styles = {

    container: {
      padding: 20,
    },
}